<template>
  <v-dialog
    v-model="value"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          {{ item.id ? "Editar" : "Cadastrar" }} Monitoramento
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-5">
        <v-card outlined>
          <v-form ref="formMonitoramento">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Nome Monitoramento"
                    v-model="item.name"
                    :counter="255"
                    :rules="requiredField"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Endereço"
                    v-model="item.address"
                    :counter="255"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete
                    :items="this.$store.getters.getGroupSelect"
                    label="Grupo"
                    v-model="item.groupId"
                    :rules="requiredField"
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                    :items="this.$store.getters.getMonitoramentosTypeSelect"
                    label="Software do Monitoramento"
                    v-model="item.softwareId"
                    :rules="requiredField"
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                    :items="this.$store.getters.getMonitoramentosTypeVmsSelect"
                    label="Software de Video Monitoramento"
                    v-model="item.softwareVmsId"
                  ></v-autocomplete>
                  <!-- :rules="requiredField" -->
                </v-col>
              </v-row>
              <v-row v-show="item.softwareId == 1">
                <v-col>
                  <v-text-field
                    label="Token"
                    hint="Insira o token do usuario do Software (SITUATOR)"
                    persistent-hint
                    v-model="item.token"
                    :counter="255"
                    :rules="item.softwareId == 1 ? requiredField : []"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="item.description"
                    rows="3"
                    label="Descrição"
                    hint="Digite uma descrição se necessario"
                  ></v-textarea>
                </v-col>
              </v-row>
              <!-- IPS -->
              <v-row>
                <v-col>
                  <v-card outlined>
                    <v-card-title>
                      IP's
                      <v-spacer> </v-spacer>
                      <v-btn icon @click="dialogIP = true">
                        <v-icon color="success"> mdi-plus </v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <TableIps
                        :item="item.ips || []"
                        :enableDelete="true"
                        @removeIP="removeIP"
                      />
                      <span v-show="ipFail" class="red--text text--lighten-1"
                        >Campo Obrigatório!</span
                      >
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!-- /IPS -->
              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="$emit('closeDialoag')"
                >
                  Cancelar
                </v-btn>
                <v-btn color="success" text @click="save"> Salvar </v-btn>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-card-text>
    </v-card>
    <DialogIP v-model="dialogIP" @addIp="addIP" />
  </v-dialog>
</template>

<script>
import { requiredField } from "../../../Utils/InputValidation";
import DialogIP from "./DialogIp.vue";
import TableIps from "./TableIps.vue";
export default {
  name: "FormMonitoramento",
  components: {
    TableIps,
    DialogIP,
  },
  props: {
    item: {
      type: Object,
      required: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialogIP: false,
      requiredField,
      ipFail: false,
    };
  },
  methods: {
    addIP(configIp) {
      if (!Array.isArray(this.item.ips)) {
        this.item.ips = [];
      }
      this.item.ips.push(configIp);
      this.dialogIP = false;
    },
    removeIP(index) {
      let ip = this.item.ips[index];
      if (ip.id) {
        this.$store.dispatch("deletMonitoramentoIp", ip);
        this.item.ips.splice(index, 1);
      } else {
        this.item.ips.splice(index, 1);
      }
    },
    save() {
      if (!this.$refs.formMonitoramento.validate()) {
        this.ipFail = true;
        return;
      }
      if (!this.item.ips) {
        this.ipFail = true;
        return;
      }
      if (this.item.ips.length == 0) {
        this.ipFail = true;
        return;
      }
      this.$emit("save", this.item);
    },
    close() {
      this.$refs.formMonitoramento.resetValidation();
      this.ipFail = false;
      this.$emit("closeDialoag");
    },
  },
  mounted() {
    if (this.$store.getters.getGroups.length == 1) {
      this.item.groupId = this.$store.getters.getGroups[0].id;
    }
  },
};
</script>

<style>
</style>