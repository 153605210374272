var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" Monitoramentos "),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"success"},on:{"click":function($event){_vm.showForm = !_vm.showForm}}},[_vm._v(" Adicionar Monitoramento ")])],1),_c('FormMonitoramento',{attrs:{"item":_vm.monitoramento},on:{"closeDialoag":_vm.closeDialoag,"save":_vm.save},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}})],1),_c('v-card',{staticClass:"mt-2"},[_c('v-simple-table',{attrs:{"dark":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("ID")]),_c('th',{staticClass:"text-left"},[_vm._v("Nome")]),_c('th',{staticClass:"text-left"},[_vm._v("Ips")]),_c('th',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.getters.showGroups),expression:"$store.getters.showGroups"}],staticClass:"text-left"},[_vm._v(" Grupo ")]),_c('th',[_vm._v("Software")]),_c('th',{staticClass:"text-center"},[_vm._v("Ações")])])]),_c('tbody',_vm._l((_vm.getMonitoramentos),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('TableIps',{attrs:{"item":item.ips}})],1)],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.getters.showGroups),expression:"$store.getters.showGroups"}]},[_vm._v(" "+_vm._s(_vm.$store.getters.getGroupNameById(item.groupId))+" ")]),_c('td',[_c('v-row',[_vm._v(" "+_vm._s(_vm.$store.getters.getMonitoramentoTypeById(item.softwareId))+" ")]),_c('v-row',[_vm._v(" "+_vm._s(_vm.$store.getters.getMonitoramentoTypeVmsById( item.softwareVmsId ))+" ")])],1),_c('td',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openVerMonitoramento(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.edit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.del(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir")])])],1)])}),0)]},proxy:true}])}),_c('VerMonitoramento',{attrs:{"item":_vm.monitoramento},model:{value:(_vm.verMonitoramento),callback:function ($$v) {_vm.verMonitoramento=$$v},expression:"verMonitoramento"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }