<template>
  <div>
    <v-card>
      <v-card-title>
        Monitoramentos
        <v-spacer> </v-spacer>
        <v-btn text color="success" @click="showForm = !showForm">
          Adicionar Monitoramento
        </v-btn>
      </v-card-title>
      <!-- FORM -->
      <FormMonitoramento
        v-model="showForm"
        :item="monitoramento"
        @closeDialoag="closeDialoag"
        @save="save"
      />
    </v-card>
    <!-- TABELA -->
    <v-card class="mt-2">
      <v-simple-table dark>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ID</th>
              <th class="text-left">Nome</th>
              <th class="text-left">Ips</th>
              <th class="text-left" v-show="$store.getters.showGroups">
                Grupo
              </th>
              <th>Software</th>
              <th class="text-center">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in getMonitoramentos" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <!-- ips -->
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <TableIps :item="item.ips" />
                </v-tooltip>
              </td>
              <!-- grupo -->
              <td v-show="$store.getters.showGroups">
                {{ $store.getters.getGroupNameById(item.groupId) }}
              </td>
              <!-- tipo de monitoramento -->
              <td>
                <!-- software de monitoramento -->
                <v-row>
                  {{ $store.getters.getMonitoramentoTypeById(item.softwareId) }}
                </v-row>
                <!-- software de video monitoramento -->
                <v-row>
                  {{
                    $store.getters.getMonitoramentoTypeVmsById(
                      item.softwareVmsId
                    )
                  }}
                </v-row>
              </td>
              <td class="text-center">
                <!-- ver monitoramento -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="openVerMonitoramento(item)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Ver</span>
                </v-tooltip>
                <!-- editar monitoramento -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="edit(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
                <!-- excluir monitoramento -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="del(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Excluir</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <VerMonitoramento :item="monitoramento" v-model="verMonitoramento" />
    </v-card>
  </div>
</template>

<script>
// import DialogIP from "./components/DialogIp.vue";
import TableIps from "./components/TableIps.vue";
import VerMonitoramento from "./components/VerMonitoramento.vue";
import FormMonitoramento from "./components/FormMonitoramento.vue";
export default {
  name: "Monitoramentos",
  components: {
    // DialogIP,
    TableIps,
    VerMonitoramento,
    FormMonitoramento,
  },
  data: () => {
    return {
      search: "",
      ip: "",
      port: 8080,
      portVms: 8081,
      showForm: false,
      dialog: false,
      verMonitoramento: false,
      monitoramento: {
        name: "",
        groupId: 0,
        ips: [],
        softwareId: 0,
        softwareVmsId: 0,
        token: "",
      },
    };
  },
  methods: {
    get() {
      this.$store.dispatch("loadMonitoramentos");
      this.$store.dispatch("loadGrupos");
      this.$store.dispatch("loadMonitoramentosTipo");
      this.$store.dispatch("loadMonitoramentosTipoVms");
    },
    closeDialoag() {
      this.monitoramento = {};
      this.showForm = false;
    },
    openDialoag(monitoramento) {
      if (monitoramento) {
        this.monitoramento = monitoramento;
      }
      this.dialog = true;
    },
    openVerMonitoramento(monitoramento) {
      if (monitoramento) {
        this.monitoramento = monitoramento;
      }
      this.verMonitoramento = true;
    },
    save(monitoramento) {
      this.$store.dispatch("saveMonitoramento", monitoramento);
      this.closeDialoag();
    },
    del(item) {
      this.$store.dispatch("deletMonitoramento", item);
    },
    // addIP(configIp) {
    //   console.log(configIp);

    //   if (!Array.isArray(this.monitoramento.ips)) {
    //     this.monitoramento.ips = [];
    //   }
    //   this.monitoramento.ips.push(configIp);
    //   this.dialog = false;
    //   this.ip = "";
    //   this.port = "";
    //   this.portVms = "";
    // },
    // removeIP(index) {
    //   let ip = this.monitoramento.ips[index];
    //   // console.log(ip);
    //   if (ip.id) {
    //     this.$store.dispatch("deletMonitoramentoIp", ip);
    //     this.monitoramento.ips.splice(index, 1);
    //   } else {
    //     this.monitoramento.ips.splice(index, 1);
    //   }
    // },
    edit(item) {
      this.showForm = true;
      this.monitoramento = item;
      this.$vuetify.goTo(0, { easing: "easeInOutCubic", duration: 300 });
    },
  },
  computed: {
    getMonitoramentos: {
      get() {
        return this.$store.getters.getMonitoramentos;
      },
      set() {},
    },
  },
  mounted() {
    this.get();
  },
};
</script>

<style>
</style>