<template>
  <v-dialog
    v-model="value"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Ver Monitoramento</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="$emit('input', false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <div>
          <v-simple-table >
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="font-weight-black">Indice:</td>
                  <td>{{ item.id }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Nome:</td>
                  <td>{{ item.name }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Endereço da Base:</td>
                  <td>{{ item.address }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Grupo:</td>
                  <td>{{ $store.getters.getGroupNameById(item.groupId) }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Software de Monitoramento:</td>
                  <td>{{ $store.getters.getMonitoramentoTypeById(item.softwareId) }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Software de Vídeo Monitoramento:</td>
                  <td>{{ $store.getters.getMonitoramentoTypeVmsById(item.softwareVmsId) }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Descrição:</td>
                  <td>{{ item.description }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Endereços de ip:</td>
                  <td><TableIps :item="item.ips" /></td>
                </tr>
                <tr>
                  <td class="font-weight-black">Cadastrado em:</td>
                  <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Editado em:</td>
                  <td>{{ new Date(item.updatedAt).toLocaleString() }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <v-divider></v-divider>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TableIps from "./TableIps.vue";
export default {
  name: "VerMonitoramento",
  components:{
    TableIps,},
  props: {
    item: {
      type: Object,
      required: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style>
</style>