<template>
  <div>
    <v-dialog v-model="value" width="500" persistent>
      <v-card>
        <v-card-title> Adicionar IP </v-card-title>
        <v-card-text>
          <v-form ref="formIp" v-model="valid" lazy-validation>
            <v-text-field
              label="IPv4"
              v-model="ip.ip"
              :counter="15"
              :rules="ipRules"
              clearable
            ></v-text-field>
            <v-row>
              <v-col>
                <v-text-field
                  label="Porta software de monitoramento"
                  v-model="ip.port"
                  :counter="5"
                  min="0"
                  max="65535"
                  type="number"
                  :rules="portRules"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Porta software VMS"
                  v-model="ip.vmsPort"
                  :counter="5"
                  min="0"
                  max="65535"
                  type="number"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="close"> Cancelar </v-btn>
          <v-btn color="primary" text @click="addIP"> Adicionar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  ipField,
  portField,
  portFieldNotRequired,
} from "../../../Utils/InputValidation";
export default {
  name: "DialogIp",
  props: ["value"],
  data: () => ({
    ip: {
      ip: "",
      port: "",
      vmsPort: "",
    },
    valid: true,
    ipRules: ipField,
    portRules: portField,
    portRulesNotRequired: portFieldNotRequired,
  }),
  methods: {
    close() {
      this.$emit("input", false);
    },
    addIP() {
      if (this.$refs.formIp.validate()) {
        this.$emit("addIp", this.ip);
        this.ip = {
          ip: "",
          port: "",
          vmsPort: "",
        };
      }
    },
  },
};
</script>

<style scoped>
</style>