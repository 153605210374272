<template>
  <v-simple-table dense class="blue-grey darken-3">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">IP</th>
          <th class="text-center">Porta</th>
          <th class="text-center">Porta VMS</th>
          <th class="text-center" v-if="enableDelete">Excluir</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(it, idx) in item" :key="idx">
          <td class="text-left">{{ it.ip }}</td>
          <td class="text-center">
            {{ it.port }}
            <ToolTipIcon
              v-if="it.port && !$store.getters.getTestPortRunning"
              text="Testar porta do monitoramento"
              icon="mdi-ethernet"
              top="true"
              @click="
                $store.dispatch('testPort', { address: it.ip, port: it.port })
              "
            />
            <v-progress-circular
              v-if="$store.getters.getTestPortRunning"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </td>
          <td class="text-center">
            {{ it.vmsPort }}

            <ToolTipIcon
              v-if="it.vmsPort && !$store.getters.getTestPortRunning"
              text="Testar porta do vms"
              icon="mdi-ethernet"
              top="true"
              @click="
                $store.dispatch('testPort', {
                  address: it.ip,
                  port: it.vmsPort,
                })
              "
            />
            <v-progress-circular
              v-if="$store.getters.getTestPortRunning"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </td>
          <td class="text-center" v-if="enableDelete">
            <v-btn icon @click="$emit('removeIP', idx)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import ToolTipIcon from "@/components/ToolTipIcon.vue";
export default {
  name: "TableIps",
  components: {
    ToolTipIcon,
  },
  props: {
    item: {
      type: Array,
      required: true,
    },
    enableDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style>
</style>